<template>
  <DashboardLayout>
    <section class="billing cms-dashboard-content scroll h-100">
      <DropDownContainer
        className="account-info"
        :select="className"
        :showInfo="showInfo"
        :close="close"
        type="billing"
      >
        <BillingInfo :data="infoAccount" />
      </DropDownContainer>
      <div class="d-flex justify-end header-billing">
        <!-- <HeaderUser /> -->
      </div>
      <div class="body pt-1 h-100">
        <div class="d-flex justify-between">
          <span
            class="
              title-section
              font-s-h1
              d-flex
              align-items-center
              font_helvetica_neue75_bold
            "
            >{{ $t('user.billing.title') }}</span
          >

          <!-- NO ELIMINAR ESTE PARRAFO, PORQUE HACE QUE SE RESETE LOS CAMPOS DE FECHA CUANDO SE CAMBIA DE COMPAÑIA (VER VARIABLE COMPUTADA companyNew ) -->
          <p class="d-none">
            {{ companyNew }}
          </p>
          <!-- {{ companyNew }} -->

          <div
            class="d-flex align-items-center header-date"
            v-if="isDesktop"
            :class="!isMobileOrDesktop() ? 'd-none' : ''"
          >
            <span class="text-gray-250 mr-1 h7 font_helvetica_neue75_bold"
              >{{ $t('user.billing.filterBy') }}
            </span>
            <Datepicker
              v-if="currentTable === 'statusAccount'"
              v-model="dateAccount"
              range
              locale="es"
              :placeholder="$t('user.billing.vencimientoPlaceholder')"
              format="dd/MM/yyyy"
              selectText="{{ $t('user.billing.apply') }}"
              cancelText="{{ $t('user.billing.cancel') }}"
              menuClassName="dp-billing"
              inputClassName="input_date_desktop"
              :textInputOptions="textInputOptions"
              @update:modelValue="setAccountDates"
            />
            <Datepicker
              v-else
              v-model="dateBilling"
              range
              locale="es"
              :placeholder="$t('user.billing.datePlaceholder')"
              format="dd/MM/yyyy"
              selectText="{{ $t('user.billing.apply') }}"
              cancelText="{{ $t('user.billing.cancel') }}"
              menuClassName="dp-billing"
              inputClassName="input_date_desktop"
              :textInputOptions="textInputOptions"
              @update:modelValue="setBillingDates"
            />
          </div>
          <div class="calendar-mobile" v-if="!showInfo">
            <span class="font-s-reg ml-1"
              >{{ $t('user.billing.filterBy') }}
            </span>
            <Datepicker
              v-model="dateAccount"
              v-if="currentTable === 'statusAccount'"
              range
              menuClassName="dp-billing"
              selectText="{{ $t('user.billing.apply') }}"
              cancelText="{{ $t('user.billing.cancel') }}"
              :textInputOptions="textInputOptions"
              @update:modelValue="setAccountDates"
              format="dd/MM/yyyy"
              locale="es"
              @open="open"
              @closed="open"
              @internalModelChange="console.log('cambio')"
              :inputClassName="selectDateMobile ? 'select-calendar' : ''"
            />
            <Datepicker
              v-model="dateBilling"
              v-else
              range
              menuClassName="dp-billing"
              selectText="{{ $t('user.billing.apply') }}"
              cancelText="{{ $t('user.billing.cancel') }}"
              :textInputOptions="textInputOptions"
              @update:modelValue="setBillingDates"
              format="dd/MM/yyyy"
              locale="es"
              @open="open"
              @closed="open"
              @internalModelChange="console.log('cambio')"
              :inputClassName="selectDateMobile ? 'select-calendar' : ''"
            />
          </div>
        </div>

        <div
          class="form-content buttons-options px-btns-0 d-flex mt-1 xs-mt gap-1"
        >
          <button
            class="btn-option"
            :class="
              currentTable === 'statusAccount'
                ? 'selectedOption'
                : 'noSelectedOption'
            "
            @click="changeForm('statusAccount'); gtmStatusAccount();"
          >
            {{ $t('user.billing.accountStates') }}
          </button>
          <button
            class="btn-option"
            :class="
              currentTable === 'report' ? 'selectedOption' : 'noSelectedOption'
            "
            @click="changeForm('report'); gtmReport();"
          >
            {{ $t('user.billing.billingReport') }}
          </button>
        </div>
        <div class="semibold text-gray-350 mt-1 dates-mobile d-inline-flex align-items-center" v-if="!isDesktop && dates.account.startDate && dates.account.endDate&& currentTable === 'statusAccount'">
          <h5>{{dates.account.startDate.split('-')[2] + '/' + dates.account.startDate.split('-')[1] + '/' + dates.account.startDate.split('-')[0] +' - '+
           dates.account.endDate.split('-')[2] + '/' + dates.account.endDate.split('-')[1] + '/' + dates.account.endDate.split('-')[0]}} </h5>
          <i class="fas fa-times mx-1" @click="clear('statusAccount')"></i>
        </div>
        <div class="semibold text-gray-350 mt-1 dates-mobile d-inline-flex align-items-center" v-if="!isDesktop 
        && dates.billing.startDate && dates.billing.endDate
        && currentTable === 'report'">
             <h5>{{dates.billing.startDate.split('-')[2] + '/' + dates.billing.startDate.split('-')[1] + '/' + dates.billing.startDate.split('-')[0] +' - '+
           dates.billing.endDate.split('-')[2] + '/' + dates.billing.endDate.split('-')[1] + '/' + dates.billing.endDate.split('-')[0]}} </h5>
            <i class="fas fa-times mx-1" @click="clear('report')"></i>
        </div>
        <br />
        <div class="pb-1">
          <p class="semibold text-gray-350 last-update mt-1">
            {{
              currentTable === 'statusAccount'
                ? accountState.lastUpdate
                : billingReport.lastUpdate
            }}
          </p>
        </div>
        <LoadingComponent v-if="isLoading" />
        <TableBillingReport
          v-if="currentTable === 'report' && !isLoading"
          :clickInfo="setInfoDropdown"
          :data="billingReport.data.data"
          :file="billingReport.fileUrl"
          :date="dates.billing"
        />
        <TableStateAccount
          v-if="currentTable === 'statusAccount' && !isLoading"
          :clickInfo="setInfoDropdown"
          :data="accountState.data.data"
          :file="accountState.fileUrl"
          :fileExcel="accountState.fileUrlExcel"
          :date="dates.account"
        />
      </div>
    </section>
    <div class="bg-modal-mobile" v-if="selectDateMobile"></div>
  </DashboardLayout>
</template>
<style lang="scss">
@import '@/modules/user/styles/style.scss';
@import '@/modules/user/styles/components/dashboard-tables.scss'; //
@import '@/modules/user/styles/pages/dashboard-billing.scss'; //
@import '@/styles/components/_buttons.scss';
</style>
<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import BillingEmptyComponent from '@/modules/user/components/dashboard-billing/BillingEmptyComponent.vue';
import HeaderUser from '@/modules/user/components/HeaderUser';
import { computed, ref } from 'vue';
import TableStateAccount from '@/modules/user/components/dashboard-billing/BillingStatusAccount.vue';
import TableBillingReport from '@/modules/user/components/dashboard-billing/BillingReport.vue';
import InfoAccount from '@/modules/user/components/dashboard-accounts/InfoAccount';
import DropDownContainer from '@/modules/shared/components/ui/DropDownContainer';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import useDropdown from '@/composables/useDropdown';
import BillingInfo from '@/modules/user/components/dashboard-billing/BillingInfo';
import useBilling from '../../composables/Dashboard/useBilling';
import { useStore } from 'vuex';
import { LoadingComponent } from '@/components';
import gaFunction from '@/utils/ga/gaFunction';
export default {
  components: {
    DashboardLayout,
    BillingEmptyComponent,
    TableStateAccount,
    TableBillingReport,
    HeaderUser,
    Datepicker,
    InfoAccount,
    LoadingComponent,
    DropDownContainer,
    BillingInfo,
  },
  beforeCreate() {
    this.getStates();
    this.getAccountData();
    this.getBillingData();
  },
  methods:{
    gtmStatusAccount(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Estado de cuenta'});      
    },
    gtmReport(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Reporte'});  
    }
  },
  setup() {
    const store = useStore();
    const currentTable = ref('statusAccount');
    const selectDateMobile = ref(false);

    const open = () => {
      selectDateMobile.value = !selectDateMobile.value;
    };

    const textInputOptions = ref({
      enterSubmit: true,
      format: 'dd/MM/yyyy',
      openMenu: true,
    });
    const changeForm = (form) => {
      currentTable.value = form;
    };

    // watchEffect(() => console.log('dateBilling', this.getBillingData()));

    const {
      clear,
      getStates,
      setAccountDates,
      setBillingDates,
      dateAccount,
      dateBilling,
      isDesktop,
      isMobileOrDesktop,
      pagebillingAct,
      pageAccountAct,
      accountPages,
      billingPages,
      setAccountPages,
      setBillingPages,
      isLoading,
      getAccountData,
      getBillingData,
      setAccountPage,
      setBillingPage,
      dates,
      isSearch,
    } = useBilling();
    return {
      open,
      changeForm,
      currentTable,
      ...useDropdown(),
      clear,
      getStates,
      setAccountDates,
      setBillingDates,
      dateAccount,
      dateBilling,
      isDesktop,
      isMobileOrDesktop,
      pagebillingAct,
      pageAccountAct,
      accountPages,
      billingPages,
      setAccountPages,
      setBillingPages,
      isLoading,
      getAccountData,
      getBillingData,
      setAccountPage,
      setBillingPage,
      dates,
      isSearch,
      ...store.state.user.billing,
      textInputOptions,
      companyNew: computed(() => {
        dateAccount.value = [];
        dateBilling.value = [];

        dates.value = {
          account: {
            startDate: '',
            endDate: '',
          },
          billing: {
            startDate: '',
            endDate: '',
          },
        };
        return store.getters['user/getCompany'];
      }),

      selectDateMobile,
    };
  },
};
</script>
