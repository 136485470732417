<template>
  <DashboardLayout>
    <section
      class="
        balances
        cms-dashboard-content cms-dashboard-content-other-question
        scroll
        font_helvetica_neue55_roman
      "
    >
      <!-- <HeaderUser /> -->
      <div class="body vh-50">
        <div class="main-title">
          <h1 class="title-section font_helvetica_neue75_bold">
            {{ $t('user.otherQueries.mainTitle.title') }}
          </h1>
          <p class="pt-1">
            {{ $t('user.otherQueries.mainTitle.description') }}
          </p>
        </div>
        <!-- First radio button and content -->
        <div class="mt-1">
          <input
            type="radio"
            class="custom-radio"
            name="yearend"
            id="yearend"
          />
          <label for="yearend">
            <img
              src="/images/decorations/check-inactive.png"
              alt="check"
              class="inactive"
            />
            <img
              src="/images/decorations/check.png"
              alt="check"
              class="active"
            />
            <span class="text">{{ $t('user.otherQueries.options[0].text') }}</span>
          </label>
          <div class="my-2 cobranzas-noadeudo p-3">
            <h3 class="mb-2 font_helvetica_neue75_bold text-account">
              {{ $t('user.otherQueries.options[0].confirmationTitle') }}
            </h3>
            <div class="row gap-1-5 m-none mx-md-5 cobranzas-options" v-if="!isLoading">
              <button @click="show('Saldo')" class="btn btn-secondary col-12 col-md-6 link link-modal">
                {{ $t('user.otherQueries.options[0].options[0].buttonText') }}
              </button>
              <button class="btn btn-gold col-12 col-md-6" @click="cancel">
                {{ $t('user.otherQueries.options[0].options[1].buttonText') }}
              </button>
            </div>
            <LoadingComponent v-if="isLoading" />
          </div>
        </div>
        <!-- Second radio button and content -->
        <div class="mt-1">
          <input
            type="radio"
            class="custom-radio"
            name="yearend"
            id="requestSeller"
          />
          <label for="requestSeller">
            <img
              src="/images/decorations/check-inactive.png"
              alt="check"
              class="inactive"
            />
            <img
              src="/images/decorations/check.png"
              alt="check"
              class="active"
            />
            <span class="text">{{ $t('user.otherQueries.options[1].text') }}</span>
          </label>
          <div class="my-2 cobranzas-noadeudo p-3">
            <h3 class="mb-2 font_helvetica_neue75_bold text-account">
              {{ $t('user.otherQueries.options[1].confirmationTitle') }}
            </h3>
            <div class="row gap-1-5 m-none mx-md-5 cobranzas-options" v-if="!isLoading">
              <button @click="show('Vendedor')" class="btn btn-secondary col-12 col-md-6 link-modal-desktop link link-modal">
                {{ $t('user.otherQueries.options[1].options[0].buttonText') }}
              </button>
              <button type="button" class="btn btn-gold col-12 col-md-6" @click="cancel">
                {{ $t('user.otherQueries.options[1].options[1].buttonText') }}
              </button>
            </div>
            <LoadingComponent v-if="isLoading" />
          </div>
        </div>
      </div>
    </section>
    <ModalLayout :isShow="isShow">
      <ModalContainer
        v-if="!isLoading"
        :close="showOrClose"
        :typeModal="typeModal"
        :imageModal="
          messageForm.messageType === 'success'
            ? '/images/decorations/success.png'
            : '/images/decorations/sorry.png'
        "
        :title="
          messageForm.messageType === 'success' ? $t('user.cobranzas.common.congratulations') : $t('user.cobranzas.common.sorry')
        "
        :description="messageForm.message"
        :type="messageForm.messageType"
      />
    </ModalLayout>
  </DashboardLayout>
</template>
<style lang="scss">
@import '@/modules/user/styles/pages/dashboard-collection.scss'; //
@import '@/modules/user/styles/style.scss';
</style>
<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import Email from '../email-confirmated/EmailConfirmatedView.vue';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import useModal from '@/composables/useModal';
import useEmail from '@/modules/user/composables/Dashboard/useEmail';
import LoadingComponent from '@/components/LoadingComponent.vue';
import HeaderUser from '@/modules/user/components/HeaderUser';
import gaFunction from '@/utils/ga/gaFunction';
export default {
  components: {
    DashboardLayout,
    Email,
    ModalLayout,
    ModalContainer,
    LoadingComponent,
    HeaderUser,
  },
  setup() {
    return {
      ...useModal(),
      ...useEmail(),
    };
  },
  methods: {
    async show(type) {
      this.isShow = !this.isShow;
      await this.sendEmail_(type);
      this.gtmSendMail();
    },
    cancel() {
      document.querySelector('input[type="radio"]:checked').checked = false;
      this.gtmCancel();
    },
    gtmCancel(){
      gaFunction('event', 'clic', { 'event_category' : 'Otras consultas', 'event_label' : 'Cancelar solicitud'});
    },
    gtmSendMail(){
      gaFunction('event', 'clic', { 'event_category' : 'Otras consultas', 'event_label' : 'Realizar solicitud'});
    }
  },
};
</script>
