<template>
  <div class="account-process-body mt-2 ml-3 mr-2 mb-3">
    <h1 class="text-center mb-2 font_helvetica_neue75_bold text-primary">
      {{ $t('user.accounts.paymentProcess.pageTitle') }}
    </h1>
    <div>
      <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
        {{ $t('user.accounts.paymentProcess.ventanilla.title') }}
      </h3>
      <ul class="list-steps mb-2 text-sidebar">
        <li>
          <p class="font_helvetica_neue55_roman">
            {{ $t('user.accounts.paymentProcess.ventanilla.steps.0') }}
          </p>
        </li>
        <li>
          <p class="font_helvetica_neue55_roman">
            {{ $t('user.accounts.paymentProcess.ventanilla.steps.1') }}
          </p>
        </li>
        <li>
          <img
            src="/images/decorations/process-1.png"
            class="img-process"
            align="right"
            alt=""
          />
          <p class="font_helvetica_neue55_roman">
            {{ $t('user.accounts.paymentProcess.ventanilla.steps.2') }}
          </p>
        </li>
        <li>
          <p class="font_helvetica_neue55_roman">
            {{ $t('user.accounts.paymentProcess.ventanilla.steps.3') }}
          </p>
        </li>
      </ul>
    </div>

    <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
      {{ $t('user.accounts.paymentProcess.bcpChannel.title') }}
    </h3>
    <ul class="list-steps mb-2 text-sidebar">
      <li>
        <img
          src="/images/decorations/process-2.png"
          class="img-process"
          align="right"
          alt=""
        />
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.bcpChannel.steps.0') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.bcpChannel.steps.1') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.bcpChannel.steps.2') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.bcpChannel.steps.3') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.bcpChannel.steps.4') }}
        </p>
      </li>
    </ul>

    <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
      {{ $t('user.accounts.paymentProcess.mobileBanking.title') }}
    </h3>
    <ul class="list-steps mb-2 text-sidebar">
      <li>
        <img
          src="/images/decorations/process-3.png"
          class="img-process"
          align="right"
          alt=""
        />
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.mobileBanking.steps.0') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.mobileBanking.steps.1') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.mobileBanking.steps.2') }}
        </p>
      </li>
    </ul>

    <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
      {{ $t('user.accounts.paymentProcess.telecreditoPayment.title') }}
    </h3>
    <ul class="list-steps mb-2 text-sidebar">
      <li>
        <img
          src="/images/decorations/process-4.png"
          class="img-process"
          align="right"
          alt=""
        />
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.0') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.1') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.2') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.3') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.4') }}
        </p>
      </li>
      <li>
        <p class="font_helvetica_neue55_roman">
          {{ $t('user.accounts.paymentProcess.telecreditoPayment.steps.5') }}
        </p>
      </li>
    </ul>
  </div>
</template>