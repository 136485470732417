<template>
  <div class="table-accounts table-accounts-faber table-dashboard mb-3">
    <div
      class="
        table-header
        font_helvetica_neue75_bold
        table-header-account table-accounts-header
        bg-blue
      "
    >
      <div class="table-header-title">
        <p>{{ $t('user.accounts.tableHeader.bank') }}</p>
      </div>
      <div class="table-header-title cci">
        <p>{{ $t('user.accounts.tableHeader.currency') }}</p>
      </div>
      <div class="table-header-title">
        <p>{{ $t('user.accounts.tableHeader.account') }}</p>
      </div>
      <div class="table-header-title cci" v-if="type === 'faber'">
        <p>{{ $t('user.accounts.tableHeader.cci') }}</p>
      </div>
    </div>
    <div
      :key="index"
      v-for="(element, index) in data"
      class="table-content-scroll font_helvetica_neue55_roman"
    >
      <div
        class="table-info table-accounts-info table-accounts-info open-info"
        @click="clickInfo(element)"
      >
        <div class="table-info-text">
          <p>{{ element.bank }}</p>
          <p class="coin-mobile">{{ element.coin }}</p>
        </div>
        <div class="table-info-text cci">
          <p>{{ element.coin }}</p>
        </div>
        <div class="table-info-text d-flex align-items-center justify-between">
          <p>{{ element.account }}</p>
          <i class="far fa-copy copy pointer cci" @click="clickCopy(element.account)" ></i>
        </div>
        <div
          class="table-info-text d-flex align-items-center justify-between cci"
          v-if="type === 'faber'"
        >
          <p>{{ element.cci }}</p>
          <i class="far fa-copy copy pointer cci" @click="clickCopy(element.cci)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickInfo: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    clickCopy:{
      type:Function,
      required:true
    },
    data:{
      type:Array<Object> [],
      required:true
    }
  },
  
};
</script>
