<template>
  <div class="header header-account link-perfil">
    <div class="header pointer" @click="$router.push('mi-perfil')">
      <div class="header-user">
        <i class="fas fa-user-circle text-gray-150"></i>

        <p v-if="user.company_name">
          {{ user.company_name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  setup() {
    const store = useStore();
    return {
      user: computed(() => store.getters['user/getInfoUser']),
    };
  },
};
</script>
