<template>
  <DashboardLayout>
    <section class="cms-dashboard-content scroll p-0 p-relative">
      <DropDownContainer
        className="account-process"
        :select="className"
        :showInfo="showInfo"
        :close="close"
      >
        <ProccessPayment />
      </DropDownContainer>

      <DropDownContainer
        className="account-info"
        :select="className"
        :showInfo="showInfo"
        :close="close"
      >
        <InfoAccount :data="infoAccount" :clickCopy="setCopy" />
      </DropDownContainer>
      <Copy :copy="copy" :setShowCopyContainer="setShowCopyContainer" />
      <div class="header-account">
        <!-- <HeaderUser /> -->
      </div>
      <div class="p-relative">
        <div class="body accounts">
          <div class="d-flex justify-between align-items-center title-accounts">
            <p class="mb-1 font_helvetica_neue55_roman text-gray-250 text-mobile">
              {{ $t('user.accounts.accountsTable.clickRowDetail') }}
            </p>
            <h1 class="title text-primary font_helvetica_neue75_bold">
              {{ $t('user.accounts.headerAccount.pageTitle') }}
            </h1>
            <button
              type="button"
              @click="setInfo('account-process');gtmSeePayProccess();"
              class="mb-2 btn btn-default font_helvetica_neue55_roman open-process"
            >
              {{ $t('user.accounts.accountsTable.seePaymentProcess') }}
            </button>
          </div>
          <Table
            v-if="user.company === 'faber'"
            :type="selectAccount"
            :clickInfo="setInfoDropdown"
            :clickCopy="setCopy"
            :data="accountsFaber"
            @click="gtmCopyFaber()"
          />
          <Table
            v-if="user.company === 'vikingo'"
            :type="selectAccount"
            :clickInfo="setInfoDropdown"
            :clickCopy="setCopy"
            :data="accountsVikingo"
            @click="gtmCopyVikingo()"
          />
        </div>
      </div>
    </section>
  </DashboardLayout>
</template>


<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import HeaderUser from '@/modules/user/components/HeaderUser';
import Table from '@/modules/user/components/ui/Table';
import useDropdown from '@/composables/useDropdown';
import useCopy from '@/modules/user/composables/ui/useCopy';
import InfoAccount from '@/modules/user/components/dashboard-accounts/InfoAccount';
import ProccessPayment from '@/modules/user/components/dashboard-accounts/ProccessPayment';
import DropDownContainer from '@/modules/shared/components/ui/DropDownContainer';
import Copy from '@/modules/user/components/dashboard-accounts/ui/Copy';
import { useStore } from 'vuex';
import gaFunction from '@/utils/ga/gaFunction';

export default {
  components: {
    DashboardLayout,
    HeaderUser,
    Table,
    InfoAccount,
    ProccessPayment,
    Copy,
    DropDownContainer,
  },
  methods:{
    gtmSeePayProccess(){
      gaFunction('event', 'clic', { 'event_category' : 'Cuentas corrientes', 'event_label' : 'Ver proceso de pago'});
    },
    gtmCopyFaber(){
      gaFunction('event', 'clic', { 'event_category' : 'Cuentas corrientes', 'event_label' : 'Copiar número de cuenta Faber'});
    },
    gtmCopyVikingo(){
      gaFunction('event', 'clic', { 'event_category' : 'Cuentas corrientes', 'event_label' : 'Copiar número de cuenta Vikingo'});
    }
  },
  setup() {
    const store = useStore();
    const accountsFaber = [
      {
        id: 1,
        bank: 'BCP',
        coin: 'PEN',
        account: '191-0326127-0-11',
        cci: '219100032612701000',
      },
      {
        id: 2,
        bank: 'BBVA',
        coin: 'PEN',
        account: '0011-0686-01-00007070',
        cci: '1168600010000700000',
      },
      {
        id: 3,
        bank: 'SCOTIABANK',
        coin: 'PEN',
        account: '000-5521734',
        cci: '917000000552173000',
      },
       {
        id: 4,
        bank: 'INTERBANK',
        coin: 'PEN',
        account: '2003-0038-70110',
        cci: '00320000300387011035',
      }
    ];
    const accountsVikingo = [
      {
        id: 1,
        bank: 'BCP',
        coin: 'PEN',
        account: '193-1807905-0-65',
        cci: '219100032612701000',
      },
      {
        id: 2,
        bank: 'BBVA',
        coin: 'PEN',
        account: '0011-0380-01-00027760',
        cci: '1168600010000700000',
      }
    ];
    return {
      ...useDropdown(),
      ...useCopy(),
      ...store.state,
      accountsFaber,
      accountsVikingo,
    };
  },
};
</script>
