<template>
  <div class="account-info-body mt-3 mx-2 mb-3">
    <h2 class="text-center mb-1 font_helvetica_neue75_bold text-primary">
      Detalles de la cuenta corriente
    </h2>
    <div class="divider"></div>
    <div class="account-info-bank">
      <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">Banco :</h3>
      <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{ data.bank }}</h3>
    </div>

    <div class="account-info-coin">
      <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
        {{ data.currency }}
      </h3>
      <h3 class="mt-1 mb-2 font_helvetica_neue55_roman"></h3>
    </div>

    <div class="account-info-account">
      <h3
        class="
          mt-1
          mb-1
          font_helvetica_neue75_bold
          text-primary
          d-flex
          justify-between
          align-items-center
        "
      >
        Cuenta
      </h3>
      <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
        <p class="font_helvetica_neue55_roman">
          {{ data.account }}
        </p>
        <i
          class="far fa-copy copy pointer"
          @click="clickCopy(data.account)"
        ></i>
      </h3>
    </div>

    <div class="account-info-account">
      <h3
        class="
          mt-1
          mb-1
          font_helvetica_neue75_bold
          text-primary
          d-flex
          justify-between
          align-items-center
        "
      >
        Moneda
      </h3>
      <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
        <p class="font_helvetica_neue55_roman">
          {{ data.coin }}
        </p>
        <!-- <i class="far fa-copy copy pointer" @click="clickCopy(data.coin)"></i> -->
      </h3>
    </div>
    <div class="account-info-cci" v-if="data.hasOwnProperty('cci')">
      <h3
        class="
          mt-1
          mb-1
          font_helvetica_neue75_bold
          text-primary
          d-flex
          justify-between
          align-items-center
        "
      >
        CCI
      </h3>
      <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
        <p class="font_helvetica_neue55_roman">{{ data.cci }}</p>
        <i class="far fa-copy copy pointer" @click="clickCopy(data.cci)"></i>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    clickCopy: {
      type: Function,
      required: true,
    },
  },
};
</script>
